@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "../assets/fonts/Gilroy/gilroy.css";


:root {
  --bg-color: #fff;
  --text-color: #333;
}

/* Mode sombre */
[data-theme='dark'] {
  --bg-color: #191919;
  --text-color: #eee;
}

::-webkit-scrollbar {
  width: 2px; /* Largeur du scrollbar */
  background: var(--bg-color);
}

::-webkit-scrollbar-thumb {
  background: var(--bg-color); /* Couleur du thumb (la partie mobile) */
}

::-webkit-scrollbar-thumb:hover {
  background: var(--bg-color); /* Couleur du thumb au survol */
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);

  margin: 0;
  font-family: 'Gilroy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:14px;
  line-height:18px;
}

a {
  color:var(--text-color);
  text-decoration:none;
  border-bottom: 3px solid #F9D100;
}

a.noborder {
  border-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Pour tous les éléments */
*:focus {
  outline: none;
}

/* Ou pour des éléments spécifiques, comme les boutons */
button:focus, a:focus {
  outline: none;
}


.center-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.App {
  padding: 0 30px 30px;
  overflow: hidden;
}

.App .Personne h1, .App h2.ResultatsdeRecherche {
  margin-top:0;
}

.App .voirplus {
  float:right;
  font-size:13px;
}


.App h1 {
      font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: 800;
    padding: 0;
    text-align: left;
    text-transform: uppercase;
}

.App h2 {
  font-size: 22px;
  line-height: 22px;
    padding-bottom: 15px;
    font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
}
.App h2.m {
  font-size:16px;
}
.App h2.st {
  margin-top:50px;
}
.App h2.pbottom8 {
  padding-bottom:8px;
}
.App h2.pbottom0 {
  padding-bottom:0px;
}
.App h3.st {
  font-weight:700;
  font-size: 12px;
  line-height:14px;
  position:relative;
} 
.App h4.st {
  font-weight:400;
  font-size: 11px;
  line-height:13px;
  margin-top:0;
}
.App h3.st.cutted {
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 35px;
}

.App h3.st > div {
  float:right;
  position:absolute;
  right:0;
  top:0;
  background-color:var(--bg-color);
  padding-left:3px;
}

.text-center {
  text-align:center;
}

.App img {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 10px;
}


.App .lazy-load-image {
  background: url('../assets/img/Spinner-1s-48px.gif') no-repeat center center;
  background-size: 48px 48px;
  background-color: #fff;
}

.App .col_1_3 {
  width:30%;
  display:inline-block;
  vertical-align: top;
}
.App .col_2_3 {
  width:65%;
  display:inline-block;
  vertical-align: top;
  margin-left:5%;
}

.App .voxblocs {
  float: left;
    display: block;
    width: 100%;
}
.App .col_1_6 {
  float:left;
  display:inline-block;
  vertical-align: middle;
  width:20%;
}
.App .col_5_6 {
  padding-left: 4px;
  float:left;
  display:inline-block;
  vertical-align: middle;
  width:78%;
}

.App .linkListe {
  margin-left:4px;
  margin-right:2px;
  font-weight:500;
}

.App .linkListe2 {
  font-weight:500;
  margin-left:2px;
  display: inline-block;
}

.App .linkListe3 {
  font-weight:300;
  margin-left:2px;
  display: inline-block;
  font-style:italic;
  font-size:0.9em;
}

.App .actuInfos {
  padding: 2px 4px;
    margin-right: 4px;
    display: inline-block;
    margin-bottom: 4px;
    color: #191919;
    background-color: #ECECEC!important;
    border-radius: 4px;
  font-size:13px;
}

.App .actuInfos2 {
  padding: 2px 4px;
    margin-right: 4px;
    display: inline-block;
    margin-bottom: 4px;
    color: #191919;
    font-weight:500;
    background-color: #F9D100!important;
    border-radius: 4px;
  font-size:13px;
}
.App .actuInfos2 a {
  color:#111;
}

.App .personneInfos {
  border-left:5px solid #F9D100;
  padding-left:10px;
}

.App p.noData, .App div.noData {
  margin:0;
  padding:0;
  height:0;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.iframe-responsive {
  position: relative;
  width: 100%;
  border: 0;
}


.aucuneinfo {
  color:#000000;
  background-color:#F9D100;
  text-align:center;
  border-radius: 24px;
  padding:25px 19px;
  font-weight:500;
}


.App .Serie .cover-container {
  margin-left: -30px;
    margin-right: -30px;
}

.App .Serie .cover {
    display: grid;
    place-items: center;
    height: 190px;
    overflow: hidden;
    align-content: space-evenly;
    background-color: #000;
}

.App .Serie .cover img {
  max-width: 100%;
  max-height: 100%;
  border-radius:10px;
}

.App .searchbar {
  display: block;
    position: relative;
    float: left;
    width: 100%;
    clear: left;
    margin-bottom: 30px;
}
.App .Roles .searchbar {
  margin-bottom:10px;
}
.App .helper-form-txt {
  margin-top:0;
}
.App .FormRecherche {
  width: 100%;
    clear: both;
    display: block;
    float: left;

    background: #FFF;
        border: 1px solid #F9F9F9;
}

.App .FormRecherche input {
  padding: 10px;
    width: 75%;
    display: inline-block;
    float: left;
    height: 20px;
    border: 0;
}

.App .FormRecherche button {
  padding: 12px 0;
    width: 14%;
    display: inline-block;
    float: right;
    height: 100%;
    border: 0;
    vertical-align: middle;
    background-color:#FAFAFA;
}

.App .btn-back {
  text-decoration:none;
  border-bottom: 0;
}


.App .nomargintop {
  margin-top:0;
}
.App .nomarginbottom {
  margin-bottom:0;
}

.App .marginbottom30 {
  margin-bottom:30px;
}

.App .margintop30 {
  margin-top:30px;
}


.App .gender-female {
  color:#FFB1CB;
}
.App .gender-male {
  color:#01A6EA;
}


.App img.round-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  object-fit: cover;
  text-align:center;
}
.App .image-container {
  width: 100%;
  padding-bottom: 100%; /* pour maintenir un ratio d'aspect de 1:1 */
  position: relative; /* si le parent est redimensionné, l'enfant (.round-image) le sera aussi */
  overflow: hidden;
  border-radius: 0%; /* arrondit le conteneur */
  margin: 0 auto;
}


.App .voix_img_container {
  height: 40px;
  width:40px;
  border-radius: 50%;
  overflow: hidden;
  position:relative;
}
.App img.voix_img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  object-fit: cover;
  text-align:center;
}



.App h1.ResultatsdeRecherche {
  margin-bottom:30px;
}
.App .Liste {
  margin-bottom:25px;
}


/* MODAL */
.ReactModal__Content.ReactModal__Content--after-open button {
  right: 10px;
    position: absolute;
    top: 10px;
}
.ReactModal__Content.ReactModal__Content--after-open {
  background: var(--bg-color) !important;
  padding:0 !important;
}




/* REACT WINDOW */
.List, .ListActu {
  width:calc(100% + 30px) !important;
  overflow-y: hidden!important;
}
.List a, .ListActu a {
  border-bottom: 0;
  position:relative;
}
.App .List h3.st, .App .ListActu h3.st {
  margin:4px 0;
}

/* Pour masquer la scrollbar dans les navigateurs WebKit (Chrome, Safari) */
.List::-webkit-scrollbar, .ListActu::-webkit-scrollbar {
  display: none;
}

/* Pour masquer la scrollbar dans IE et Edge */
.List, .ListActu {
  -ms-overflow-style: none;
}

/* Pour masquer la scrollbar dans Firefox */
.List, .ListActu {
  scrollbar-width: none;
}


/* SLICK */
.slick-slider.galery .slick-slide .galery-container {
  height:300px;
}
.slick-slider.galery .slick-slide img {
  height: 100%;  /* Définissez la hauteur que vous souhaitez */
  width: auto;
  margin: auto;
  object-fit: cover;
}

.App .slick-slider img {
  height:100%;
}

.slick-slider {
  margin-right: -30px;
}
.Casting .slick-slide > div {
    margin-right: 10px;
}
.slick-slide > div {
  margin-right:20px;
}
.slick-slide h3 {
  display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 35px;
}
.slick-slide h3, .slick-slide h4 {
  margin:5px 0 0;
}


.slick-prev, .slick-next {
  display:none;
  visibility:hidden;
}

.slick-prev {
  left: -15px;
}
.slick-next {
    right: -15px;
}
.slick-prev:before, .slick-next:before {
  color:#000;
}
.slick-list> div {
  margin-left:0;
}



.burger-menu {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
  cursor: pointer;
  background-color: #FFF;
    border-radius: 50%;
    padding: 10px;
    font-size: 18px;
    color: #000;
}


.App .pagination {
  overflow-x: auto;
  white-space: nowrap;
}
.App .pagination ul {
  margin: 30px 0 0 0;
  padding: 0;
  display: inline-block;
}
.App .pagination ul li {
  list-style-type: none;
  display: inline-block;
}
.App .pagination ul li a {
  border: 3px solid #F9D100;
  padding:8px;
  margin-right:4px;
  color: var(--text-color);
  font-weight: 700;
  border-radius:4px;
  margin-bottom: 5px;
  display: inline-block;
}
.App .pagination ul li.current a {
  color:#FFF;
  background-color:#F9D100;
}



.menucache {
  display:none;
  visibility:hidden;
}
/* MENU SIMPLE */
.menu-list {
  display: none;
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 99;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #FFF;
   /* background-image: url('../assets/img/popcorn.jpg');
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;*/
}

.menu-list.show {
  display: block;
}

.menu-item {
  padding: 10px;
  width:100px;
}
.menu-item a {
  border-bottom:0;
  font-weight:500;
  font-size:14px;
  color:#000;
}

.menu-item:last-child {
  border-bottom: none;
}



/* MENU SIDEBAR */
.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: #FED900;
    background-image: url('../assets/img/popcorn.jpg');
    background-size: contain;
    background-position: bottom left;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 99;
    background-repeat: no-repeat;
 
}

.sidebar.show {
  left: 0;
}

.sidebar-item {
  padding: 15px;
  text-align: center;
  color: #000000;
  display: block;
  transition: 0.3s;
}

.sidebar-item:hover {
  color: #f1f1f1;
}




.App img.logo_vod {
  height:50px;
  border-radius:4px;
  width:50px;
  margin-right:4px;
}




.tagsE {
  position: absolute;
    top: -9px;
}

.actuALU {
  padding:2px;color:#FFF;background-color:#F9D100!important;border-radius: 4px;display:inline-block;
}
.actuBOOK {
  padding:2px;color:#FFF;background-color:#feb41c!important;border-radius: 4px;display:inline-block;
}
.actuBA {
  padding:2px;color:#FFF;background-color:#ff680d!important;border-radius: 4px;display:inline-block;
}
.actuInfos {
  padding:2px;color:#212428;background-color:#ECECEC!important;border-radius: 4px;display:inline-block;
}
.actuEvent {
  padding:2px;color:#FFF;background-color:#70b85d!important;border-radius: 4px;display:inline-block;
}
.actuCritik {
  padding:2px;color:#FFF;background-color:#197bbe!important;border-radius: 4px;display:inline-block;
}
.actuInsolite {
  padding:2px;color:#FFF;background-color:#13b7ff!important;border-radius: 4px;display:inline-block;
}
.actuMotdls {
  padding:2px;color:#FFF;background-color:#00ada7!important;border-radius: 4px;display:inline-block;
}
.actuTDM {
  padding:2px;color:#FFF;background-color:#ec2913!important;border-radius: 4px;display:inline-block;
}
.actuVID {
  padding:2px;color:#FFF;background-color:#a13d63!important;border-radius: 4px;display:inline-block;
}
.actuPeople {
  padding:2px;color:#FFF;background-color:#d1008b!important;border-radius: 4px;display:inline-block;
}
.actuCineReunion {
  padding:2px;color:#FFF;background-color:#F9D100!important;border-radius: 4px;display:inline-block;
}