@font-face {
    font-family: 'Gilroy';
    font-weight:400;
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Regular.eot');
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Regular.woff') format('woff'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Regular.svg') format('svg'),
         url("https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Regular.otf") format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy';
    font-weight:800;
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Black.eot');
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Black.woff') format('woff'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Black.ttf') format('truetype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Black.svg') format('svg'),
         url("https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Black.otf") format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy';
    font-weight:700;
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-ExtraBold.eot');
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-ExtraBold.ttf') format('truetype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-ExtraBold.svg') format('svg'),
         url("https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-ExtraBold.otf") format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy';
    font-weight:500;
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Bold.eot');
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Bold.woff') format('woff'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Bold.svg') format('svg'),
         url("https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Bold.otf") format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy';
    font-weight:300;
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Light.eot');
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Light.woff') format('woff'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Light.ttf') format('truetype'),
         url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Light.svg') format('svg'),
         url("https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-Light.otf") format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy';
    font-style:italic;
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-RegularItalic.ttf');
    src: url('https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-RegularItalic.ttf') format('truetype'),
         url("https://www.cine974.com/themes/revija/fonts/Gilroy/Gilroy-RegularItalic.otf") format("opentype");
    font-display: swap;
  }